import React, { useState } from "react"
import styled from "styled-components"
import { Button } from "components/button"
import PressItem from "components/press/item"

const StyledPressList = styled.section`
  position: relative;
  z-index: 1;
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: 1em;
  padding: 2em 0;
  > div {
    margin: 2em 0;
    display: flex;
    justify-content: center;
  }
  @media ( min-width: 48em ) {
    grid-template: auto / repeat(3, 1fr);
    > div {
      grid-column: 1 / span 3;
    }
  }
  @media ( min-width: 60em ) {
    grid-template: auto / repeat(6, 1fr);
    > div {
      grid-column: 1 / span 6;
    }
    h2 {
      grid-column: auto / span 2;
      max-width: 7em;
    }
  }
`

const PressList = ( props ) => {


  const allPressItems = props.pressItems

  const [pressItems, setPressItems] = useState( allPressItems )

  return (
    <StyledPressList id="reviews">
      <h2 className="pageTitle">{props.title && props.title}</h2>
      {pressItems.map(({ node }) => 
        <PressItem pressItem={node} />
      )}
      { pressItems.length < allPressItems.length &&
        <div>
          <Button
            onClick={(e) => setPressItems( allPressItems )}
          >See all Press</Button>
        </div>
      }
    </StyledPressList>
  )
}

export default PressList

