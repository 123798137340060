import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import LayoutDirectory from "components/layoutDirectory"
import PressList from "components/press/list"
import AwardAnnouncement from "../components/svg/AwardAnnouncement"


export default ({ data }) => (
  <>
    <Helmet>
      <title>The Sound Sniffer Features | {data.site.siteMetadata.title}</title>
    </Helmet>
    <LayoutDirectory>
      <PressList
        title={<>In <strong>The Sound Sniffer</strong></>}
        pressItems={data.press.edges}
      />
    </LayoutDirectory>
    <AwardAnnouncement />
  </>
)

export const query = graphql`
    query {
        press: allDirectusPress(sort: {order: ASC, fields: sort}, filter: {quotation: {ne: null}, featured: {eq: false}, source: {eq: "The Sound Sniffer"}}) {
			edges {
				node {
					id
					url
					source
                    quotation,
                    is_link
                    artist_attribution {
                        name
                    }
				}
			}
		}
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`
