import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"

const StyledPressItem = styled.blockquote`
  margin: 0;
  background-color: var(--color-white);
  padding: 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  &:nth-of-type(3n + 1) {
    background-color: var(--color-gray);
    cite {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }
  &:nth-of-type(3n + 2) {
    background-color: var(--color-dark);
    color: var(--color-white);
    cite {
      background-color: var(--color-white);
      color: var(--color-dark);
    }
  }
  &:nth-of-type(4n + 1) {
    background-color: var(--color-black);
    color: var(--color-white);
    cite {
      background-color: var(--color-white);
      color: var(--color-black);
    }
  }
  &:nth-of-type(5n + 2) {
    background-color: var(--color-gray-dark);
    color: var(--color-white);
    cite {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }
  p {
    margin: 0;
    font-weight: 500;
    font-size: 1.0625em;
    &::before {
      content: '\\201c';
      margin-left:-0.375em;
    }
    &::after {
      content: '\\201d';
    }
  }
  cite {
    display: inline-block;
    
		font-size: var(--font-medium);
		font-weight: 700;
		font-style: normal;
		color: var(--color-white);
		line-height: 1;
		margin-bottom: 0.75em;
		font-size: 1em;
    background-color: var(--color-black);
    padding: 0.5em;
    margin-left: -0.5em;
  }
  > a {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .can-hover & > a:hover,
  & > a:active {
    background-color: rgba(255,255,255,0.4);
  }
  @media ( min-width: 48em ) {
    padding: 1.25em 1.5em;
  }
  @media ( min-width: 60em ) {
    padding: 1.75em 2em;
    ${props => props.contentLength > 25 && css`
      grid-column: auto / span 2;
    `}
    ${props => props.contentLength > 200 && css`
      grid-row: auto / span 2;
    `}
    ${props => props.contentLength > 400 && css`
      grid-row: auto / span 3;
    `}
    ${props => props.contentLength > 600 && css`
      grid-row: auto / span 4;
    `}
  }
`


const PressItem = ({ pressItem }) => {
  const schemaData = {
    "@context": "https://schema.org/",
    "@type": "Quotation",
    "about": {
      "@type": "Website",
      "name": "The Outlaw Ocean Music Project",
      "url": "https://www.theoutlawoceanmusic.com",
      "creator": {
        "@type": "NGO",
        "address": {
          "@type": "PostalAddress",
          "addressCountry": "United States",
          "addressLocality": "Washington",
          "addressRegion": "District of Columbia"
        },
        "description": "The Outlaw Ocean Project is a non-profit journalism organization that produces high-impact investigative stories about lawlessness at sea and the diversity of environmental, human rights, and labor abuses occurring offshore around the world.",
        "founder": {
          "@type": "Person",
          "email": "ian@theoutlawocean.com",
          "familyName": "Urbina",
          "givenName": "Ian",
          "sameAs": ["https://en.wikipedia.org/wiki/Ian_Urbina"]
        },
        "email": "media@theoutlawocean.com",
        "legalName": "The OO Project Inc.",
        "name": "The Outlaw Ocean Project",
        "nonprofitStatus": "Nonprofit501c3",
        "url": "https://www.theoutlawocean.com"
      }
    },
    "creator": {
      "@type": "Organization",
      "name": pressItem.source
    },
    "text": pressItem.quotation,
    "url": pressItem.url
  };
  return(
    <StyledPressItem contentLength={pressItem.quotation.length}>
      <cite>{pressItem.artist_attribution 
        ? `${pressItem.artist_attribution.name} in ${pressItem.source}` 
        : pressItem.source
      }</cite>
      <p>{pressItem.quotation}</p>
      {pressItem.is_link &&
        <Link to={pressItem.url}></Link>
      }
      {pressItem.url &&
        <a href={pressItem.url} target="_blank" rel="noopener noreferrer"></a>
      }
      {! pressItem.is_link && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
      )}
    </StyledPressItem>
  )
};

export default PressItem
